import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Samples_widget from '../Samples_widget/Samples_widget'
import * as Constant from '../Constant'
import { useParams } from 'react-router-dom';

function Blogs_sub() {


  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  // Fetch the blog details on component mount using fetch
  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`${Constant.BlogDetails}/${slug}` );
        
        // Check if the response is successful (status 200-299)
        if (!response.ok) {
          throw new Error('Failed to fetch blog details');
        }

        const data = await response.json(); // Parse the JSON data
        console.log('yuyyuyu',data)
        setBlog(data.data);
      } catch (err) {
        setError('Failed to fetch blog details');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, []);

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;




  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h1 class="display-3 font-weight-bold text-white">Blogs</h1>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">
              <Link class="text-white" to="/blogs">
                Blog
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}


      <section className="blog-posts-area section-gap blog-details-image">
        <div className="container">
          <div className="row  w-100 d-flex justify-content-center">
            <div
              className="col-lg-8 post-list blog-post-list"
              id="container-content-wrapper"
            >

        <div className=' w-100  mt-5'>
      <div className=' shadow-lg w-100 p-3' style={{height:"auto"}}>
        <div className='w-100'>
          <img src={blog?.image} className='w-100' style={{height:'320px'}}/>
        </div>
        <div className=' w-100 '>
      <h1 className="text-3xl font-bold text-gray-800 mt-5">{blog?.title}</h1>
      <p className="text-sm text-gray-500 mt-2 ms-5">
        {new Date(blog?.created_at).toLocaleDateString()}
      </p>
      <div className="mt-4">
      <div style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: blog.description }}></div>
      </div>
      </div>
      </div>
      </div>
             
            </div>
           
          </div>
        </div>
      </section>

    </>
  )
}

export default Blogs_sub
