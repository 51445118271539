import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Samples_widget from '../Samples_widget/Samples_widget';
import * as Constant from '../Constant'
import "./Blogs.css"

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // Make the fetch request to the API
        const response = await fetch(Constant.Blogs);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json(); // Parse the response as JSON

        // Check if the API response is successful and contains 'data'
        if (data.success && Array.isArray(data.data)) {
          setBlogs(data.data); // Set blogs data in the state
        } else {
          console.error('Error: No valid blog data found');
        }
      } catch (error) {
        console.error('Error fetching blog data', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched or error occurs
      }
    };

    fetchBlogs(); // Call the fetchBlogs function
  }, []); // Empty dependency array ensures this runs only once after initial render

  if (loading) {
    return <div>Loading...</div>; // Display loading message while fetching data
  }

  return (
    <>
      <div className="container-fluid bg-primary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: '400px' }}
        >
          <h1 className="display-3 font-weight-bold text-white">Blogs</h1>
          <div className="d-inline-flex text-white">
            <p className="m-0">
              <Link className="text-white" to="/">
                Home
              </Link>
            </p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">Blog</p>
          </div>
        </div>
      </div>
      <section className="blog-posts-area section-gap">
  <div className="container">
    <div className="row border">
      <div className="col-lg-8 post-list blog-post-list border">
        {blogs.map((blog, index) => (
          <div className="single-heading" key={index}>
            <div className="border">
              <img
                className="img-fluid "
                src={blog.image || 'defaultImage.jpg'} // Use a placeholder if imageUrl is missing
                alt={blog.title || 'Blog Image'}
              />
            </div>
            <div>
              <a href="">
                <h3 className="mt-3">{blog.title}</h3>
              </a>
            </div>
            {/* Apply truncate-description class to limit description to 3 lines */}
            <p className="text-justify mt-2 truncate-description" dangerouslySetInnerHTML={{ __html:blog.description }} />
           
            <div className="p-2 float-right">
              <Link onClick={goToTop} to={`/blogs_sub/${blog.slug}`}>
                <button
                  type="button"
                  className="btn btn-primary mt-2 py-2 px-4"
                >
                  Read More
                </button>
              </Link>
            </div><hr/>
           
            <div className="clearfix" />
          </div>
        ))}
      </div>
      {/* Right Side */}
      <div className="col-lg-4 sidebar">
        <Samples_widget />
      </div>
    </div>
  </div>
</section>


    </>
  );
}

export default Blogs;
